@use "sass:meta" as ---70mow6mkfwa;.forum {
  margin-bottom: 20px;

  //@extend .small-padding;

  .thread {
    display: block;
    clear: left;
    padding: 0;
    margin: 0 0 10px;
    border-bottom: 1px solid $medium-gray;

    &:first-child {
      border-top: 1px solid $medium-gray;
    }

    @include breakpoint(medium) {
      background-color: #b2d281;
    }
  }

  .user {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint(medium) {
      float: left;
      margin: 6px 6px 12px;
      width: 154px !important;
    }

    img {
      margin-right: 10px;
      float: left;

      @include breakpoint(medium) {
        margin: 0 0 6px;
        float: none;
      }

      @include breakpoint(small only) {
        width: 85px;
        height: 85px;
      }
    }

    @include small-padding(10px 0);
  }

  .guest {
    display: block;
    color: $dark-gray;
  }

  .post {
    display: block;
    background-color: $white;

    .smile {
      margin: 0 4px;
      border: none;
      vertical-align: top;
    }

    .bbimage {
      max-width: 99%;
      display: block;
      margin: 3px;
      border: 1px solid $medium-gray;
    }

    .text {
      margin-bottom: $global-margin * 2;
      font-size: inherit;
      line-height: $paragraph-lineheight;
      text-rendering: $paragraph-text-rendering;
    }

    @include breakpoint(medium) {
      line-height: 18px;
      margin: 0 0 0 160px;
      padding: 8px 0 8px 8px;
    }

    .pubdate {
      @include small-padding(10px 0);
    }
  }

  .buttons {
    float: right;
    display: block;
    background-color: #fffae7;
  }

  .pubdate {
    font-size: rem-calc(12);
    padding: 5px 0;
    margin-bottom: 5px;
  }

  .func {
    float: right;
    font-size: rem-calc(11);
    line-height: 18px;

    input,
    select {
      font-size: rem-calc(11);
      line-height: 18px;
    }

    form {
      display: inline;
      margin: 0;
      padding: 0;
    }

    span {
      padding: 4px;
    }
  }

  .quote {
    background-color: #f8f6e7;
    padding: 5px 10px;

    &_title {
      font-size: rem-calc(13);
      font-style: italic;
      font-weight: $global-weight-bold;
    }
  }

  &-icon {
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: middle;

    &.latest-reply {
      background-image: url('svg/forum-icon-latest-reply.svg');
      width: 12px;
      height: 16px;
    }

    &.tema {
      background-image: url('svg/forum-icon-tema.svg');
      width: 24px;
      height: 30px;
    }

    &.tema-new {
      background-image: url('svg/forum-icon-tema-new.svg');
      width: 30px;
      height: 30px;
    }

    &.circle {
      background-image: url('svg/forum-icon-circle.svg');
      width: 11px;
      height: 11px;
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3886");
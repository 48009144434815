@use "sass:meta" as ---70mow6mkfwa;.news-other {
  @include breakpoint(small) {
    @include xy-cell(100%, $gutter-position: left right, $breakpoint: 'auto');
  }

  @include breakpoint(medium) {
    @include xy-cell(34%, $gutter-position: left right, $breakpoint: 'auto');
  }

  @include breakpoint(large) {
    @include xy-cell(30%, $gutter-position: left right, $breakpoint: 'auto');
  }

  &__wrapper {
    box-shadow: 0 2px 6px 0 #2e319117;
    border-radius: $global-radius;
    padding: 10px;
    margin-bottom: 2rem;

    a {
      color: $black;

      &:hover {
        color: map-get($foundation-palette, primary);
      }
    }

    &.training__announce {
      background-image: linear-gradient(135deg, #7282ca 0%, #4e50af 40%, #131551 100%);
      color: $white;

      .rubrica {
        font-size: rem-calc(14);
      }

      .name {
        font-weight: $global-weight-bold;
        line-height: 1.4;
      }

      .date {
        margin-top: .4rem;
        font-size: rem-calc(13);
      }

      a {
        color: $white;

        .action {
          border: 1px solid $white;;
          border-radius: $global-radius;
          padding: 8px;
          display: flex;
          justify-content: center;
          font-weight: 500;
          font-size: 0.875rem;
        }
      }
    }
  }

  &__event {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &:hover {
      .action {
          background-color: $white;;
          color: map-get($foundation-palette, primary);
        }
      }
    }

  &__relevant,
  &__event,
  &__timeline {
    .title {
      font-size: rem-calc(20);
    }


    .item {
      &:first-child {
        margin-top: 12px;
      }

      &:not(:first-child) {
        margin-top: 21px;
      }

      &-title {
        font-size: rem-calc(14);
        font-weight: 500;
        line-height: 1.4;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        display: -webkit-box; /* stylelint-disable-line */
        overflow: hidden;
      }

      &-date {
        font-size: rem-calc(13);
        color: $dark-gray;
      }
    }

    &-more {
      color: $black;
      background-color: $alice-blue;
      border-radius: $global-radius;
      padding: 8px;
      display: flex;
      justify-content: center;
      font-weight: $global-weight-bolder;
      font-size: rem-calc(14);

      &:hover {
        background-color: #d2d8e1;
      }
    }
  }

  &__relevant {
    .item {
      a {
        display: flex;

        svg,
        img {
          align-self: flex-start;
          margin-right: .7rem;
          margin-top: 4px;
          width: 30px;
          height: 30px;
          flex-shrink: 0;
        }

        &-title {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          display: -webkit-box; /* stylelint-disable-line */
          overflow: hidden;
        }
      }
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3901");
@use "sass:meta" as ---70mow6mkfwa;.pagination {
  //@include small-padding();

  @include breakpoint(small only) {
    text-align: center;

    li {
      vertical-align: top;

      &.pagination-previous,
      &.pagination-next {
        a {
          position: relative;
          color: transparent;
          display: inline-block;
          width: 40px;
          overflow: hidden;
          height: 30px;
          top: 0;
          vertical-align: top;

          &::before {
            position: absolute;
            width: 100%;
            display: block;
            height: 100%;
            top: 0;
            left: 0;
            color: $black;
            line-height: 1.8;
          }
        }
      }

      &.pagination-previous {
        a {
          &::before {
            content: '\2329';
          }
        }
      }

      &.pagination-next {
        a {
          &::before {
            content: '\232A';
          }
        }

        &.is-first {
          a {
            padding-right: 30px;
            width: auto;
            text-transform: capitalize;
            color: $black;

            &::before {
              width: auto;
              left: initial;
              right: 0;
            }

            &::after {
              display: none;
            }
          }
        }
      }

      &.current {
        margin: 0 30px;
        display: inline-block;
        background-color: transparent;

        &::before {
          content: 'Стр. ';
        }

        &.is-first {
          display: none;
        }
      }
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3846");
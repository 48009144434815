@use "sass:meta" as ---70mow6mkfwa;.forum-table {
  margin-top: 0 !important;
  margin-bottom: $global-margin !important;
  border-bottom: 0;
  border-right: 0;
  width: 100%;

  th {
    background-color: #b2d281;
    border-bottom: 1px $light-black solid;
    border-right: 1px #e7f1d7 solid;
    font-size: rem-calc(13);
    font-weight: $global-weight-normal;
    padding: 5px;
    text-align: center;
  }

  td {
    border-bottom: 1px #eeecdd solid;
    border-right: 1px #eeecdd solid;
    font-size: rem-calc(14);
    padding: 8px 5px;

    &:last-child {
      border-right: 0;
    }

    p[class='forum-title'] + p {
      margin-top: 8px;
    }

    [class='gl'] + p + p,
    [class='gl'] + p {
      margin-top: 5px;
    }

    p {
      line-height: 1.3;
    }
  }

  &__main td {
    background-color: #e7f1d7;
    border-bottom: 0;
    border-right: 0;
    font-size: rem-calc(18);
    padding: 8px 0 8px 5px;
  }

  .forum-title {
    padding: 0;
    margin: 5px 0 0;
  }

  .tema-icon {
    margin-left: 8px;
    margin-top: 2px;
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3887");
@use "sass:meta" as ---70mow6mkfwa;.widget {
  @include grid-column-margin;

  overflow: hidden;

  .crop {
    overflow: hidden;
    display: -webkit-box; /* stylelint-disable-line */
    -webkit-box-orient: vertical;
  }

  @import 'company-recent';
  @import 'company-week';
  @import 'hashtags';
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3905");
@use "sass:meta" as ---70mow6mkfwa;.dl-1 {
  dt,
  dd {
    margin: 0;
    padding: 0;
    line-height: 2;
  }

  dt {
    color: $dark-gray;
    text-align: left;
    font-weight: $global-weight-normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 1rem;

    @include breakpoint(medium) {
      padding-top: 0;
      float: left;
      clear: left;
      width: 11rem;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  dd {
    @include breakpoint(medium) {
      margin-left: 12rem;
      padding-bottom: 0;
    }

    &.plain-text {
      line-height: 18px;
      padding-top: 3px;
      padding-bottom: 3px;
    }

    span.social-icon {
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }

  &.person {
    margin-bottom: 0;

    dt {
      @include breakpoint(medium) {
        width: 5rem;
      }
    }

    dd {
      @include breakpoint(medium) {
        margin-left: 6rem;
      }
    }
  }

  &.requisites {
    dt {
      @include breakpoint(medium) {
        width: 16rem;
      }
    }

    dd {
      @include clearfix;

      @include breakpoint(medium) {
        margin-left: 17rem;
      }
    }
  }

  .contacts_item {
    @include breakpoint (small only) {
      dt {
        font-size: 1.2rem;
        font-weight: $global-weight-bold;
      }
    }

    dd em {
      color: $dark-gray;
    }
  }

  .contacts_icons {
    @include breakpoint(small) {
      margin-top: .6rem;
    }

    @include breakpoint(medium) {
      display: flex;
      align-items: center;
    }

    dt,
    dd {
      line-height: normal;
    }

    dt {
      @include breakpoint (small only) {
        font-size: 1.2rem;
        font-weight: $global-weight-bold;
      }
    }

    dd {
      display: flex;

      @include breakpoint(small only) {
        margin-top: .6rem;
      }

      @include breakpoint (medium) {
        margin-left: 1rem;
      }

      span.social-icon {
        @include breakpoint (small only) {
          margin-right: 1rem;
        }

        @include breakpoint (medium) {
          display: flex;
        }
      }
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3829");
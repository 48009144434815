@use "sass:meta" as ---70mow6mkfwa;.association {
  margin-bottom: 1rem;
  overflow: hidden;

  .title {
    color: $medium-gray;
    font-weight: $global-weight-bold;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: $alice-blue;
      rotate: 110deg;
      left: 50px;
    }
  }

  &__item {
    width: 5rem;
    height: 1.5rem;
    background-repeat: no-repeat;

    span {
      display: none;
    }

    &.fairp {
      background-image: url('./svg/association/fairp.svg');
    }
  }
}

;@include ---70mow6mkfwa.load-css("sass-embedded-legacy-load-done:3878");